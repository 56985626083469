/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import _, { isEmpty } from 'lodash';
import { BooleanType, UserForUserManagement, WorkOrder, WorkOrderTeam } from '../../../../API';
import theme from '../../../../Shared/Themes/theme';
import { AlertColor, CircularProgress } from '@mui/material';
import { IUser } from '../../../../Shared/Constants/App';
import {
  fetchDataFromS3Bucket,
  formatDate,
  getAuthData,
  getStoredCustomerDetails,
} from '../../../../Shared/Utilities/utils';
import SupervisorPunchIn from './SupervisorPunchIn';
import { ITeamMemberData } from '../JobDetails/JobAssignmentCard';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { AssociateOnTheJobCard, LoaderTag } from '../ActionItems/ActionItems.Style';
import { AssignmentTeamCard } from '../JobDetails/OneTimeJobDetails.Style';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import moment from 'moment';
import { FacilityInterface } from '../../../Company/Models/Company.Model';
import { IGraphQLResponse } from '../../Services/JobCreateService';
import { AllShiftDetailsQuery, SchedulesByDateQuery } from './AssociateCard';

interface AssociateOnTheJobData {
  jobData?: WorkOrder;
  FacilityData?: FacilityInterface;
  jobId: string;
  jobsLoader?: boolean;
  specificDate?: any;
  workOrderScheduleId: string;
  woScheduleMasterId: string;
  scheduleData: SchedulesByDateQuery;
  shiftData: AllShiftDetailsQuery[];
}

const AssociateOnTheJob = ({
  jobData,
  FacilityData,
  jobId,
  jobsLoader,
  specificDate,
  woScheduleMasterId,
  workOrderScheduleId,
  scheduleData,
  shiftData,
}: AssociateOnTheJobData) => {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [teamData, setTeamData] = useState<ITeamMemberData[]>([]);
  const [associateTeamData, setAssociateTeamData] = useState<ITeamMemberData[]>([]);
  const [checkedSupervisors, setCheckedSupervisors] = useState<any>([]);
  const [isTeamDataLoading, setIsTeamDataLoading] = useState<boolean>(false);
  const [workOrderId, setWorkOrderId] = useState<string>('');
  const [workOrderData, setWorkOrderData] = useState<any>({});
  const [isAssociatePunchedIn, setIsAssociatePunchedIn] = useState(false);
  const [jobEndDate, setJobEndDate] = useState<string>('');
  const [workDayId, setWorkDayId] = useState('');
  const [isFutureShiftSelected, setIsFutureShiftSelected] = useState<boolean>(false);

  const NUMBER_OF_ITEMS_PER_PAGE = 10;

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const fetchVendorTeam = async (
    workOrderTeamResponse?: IGraphQLResponse,
    pageNumber?: number,
    limit?: number,
    searchString?: string
  ) => {
    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();

    if (!userId && !vendorId) return;

    setIsTeamDataLoading(true);
    try {
      const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);
      const supervisorDetails = getSupervisorDetails(workOrderTeamResponse?.data);
      const supervisorUserIds = supervisorDetails.map((supervisor) => supervisor.userId).join(',');
      let vendorTeamResponse = jobData?.hasChild
        ? await WorkOrdersApiService.getVendorTeam(
            userDetails?.data?.userId,
            '',
            BooleanType.False,
            searchString || '',
            1,
            10,
            true
          )
        : await OrganizationService.getAllUsers(vendorId, supervisorUserIds, '', 1, 10, BooleanType.False, true);
      if (vendorTeamResponse?.metadata?.totalCount >= NUMBER_OF_ITEMS_PER_PAGE) {
        vendorTeamResponse = jobData?.hasChild
          ? await WorkOrdersApiService.getVendorTeam(
              userDetails?.data?.userId,
              '',
              BooleanType.False,
              searchString || '',
              1,
              10,
              false
            )
          : await OrganizationService.getAllUsers(vendorId, supervisorUserIds, '', 1, 10, BooleanType.False, false);
      }
      if (vendorTeamResponse.errors.length) {
        showSnackbar(vendorTeamResponse.errors[0].message, 'error');
        setIsTeamDataLoading(false);
        return;
      }

      const vendorTeamsUsersExcludingAssociates = getSupervisorsDetails(
        workOrderTeamResponse?.data,
        vendorTeamResponse?.data ?? []
      );
      const filteredData = Array.from(
        new Map(vendorTeamsUsersExcludingAssociates.map((item) => [item.userId, item])).values()
      );

      if (!isEmpty(filteredData)) {
        const modifiedData = await getModifiedData(filteredData, workOrderTeamResponse?.data);
        setCheckedSupervisors((prevData: any) => _.uniqBy([...prevData, ...modifiedData.checkedSupervisors], 'id'));
        setTeamData(modifiedData.teamData);
      } else {
        setTeamData([]);
      }
      setIsTeamDataLoading(false);
    } catch (e) {
      console.error('Error', e);
      setTeamData([]);
      setIsTeamDataLoading(false);
    }
  };

  const getSupervisorsDetails = (workOrderTeamData: any[], vendorTeamData: any[]) => {
    const supervisorsDetails = workOrderTeamData.filter((member) => member.isSupervisor);
    return supervisorsDetails.map((supervisor) => {
      const userDetails = vendorTeamData.find((user) => user.userId === supervisor.userId);
      return {
        ...supervisor,
        ...userDetails,
      };
    });
  };

  const getModifiedData = async (vendorTeamsUsersExcludingAssociates: any[], workOrderTeamData: any[]) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedData = await Promise.all(
      vendorTeamsUsersExcludingAssociates.map(async (member: UserForUserManagement) => {
        const memberProfileUrl = await fetchDataFromS3Bucket(
          navigate,
          `associate/${member?.userId}/profile-pictures/${member?.userId}`
        );
        const { roleName = '', roleId = '' } = member?.roles?.[0] || {};
        const tempMember = {
          id: member.userId,
          name: member.username,
          isChecked: false,
          profileImg: memberProfileUrl,
          role: roleName,
          roleId,
          isSupervisor: true,
          teamId: '',
        };
        const workOrderTeamMember = workOrderTeamData.find((teamMember) => teamMember.userId === member.userId);
        if (workOrderTeamMember) {
          if (workOrderTeamMember.isSupervisor) {
            const checkedMember = {
              ...tempMember,
              isChecked: true,
              teamId: workOrderTeamMember.teamId || '',
            };
            tempCheckedMember.push(checkedMember);
            return checkedMember;
          }
          return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
        }
        return tempMember;
      })
    );
    return { teamData: modifiedData, checkedSupervisors: tempCheckedMember };
  };

  const fetchAssociateTeam = async (workOrderId: string, workOrderTeamResponse?: IGraphQLResponse) => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    const onBehalfOfAssociate = false;

    if (!userId && !vendorId) return;

    setIsTeamDataLoading(true);
    try {
      const workDayId = workOrderTeamResponse?.data[0]?.workDayId ?? '';
      const associateDetails = getAssociateDetails(workOrderTeamResponse?.data);
      const associateUserIds = associateDetails.map((associate) => associate.userId).join(',');
      const associateTeamResponse = await OrganizationService.getAllUsers(vendorId, associateUserIds);
      if (associateTeamResponse.errors.length) {
        showSnackbar(associateTeamResponse.errors[0].message, 'error');
        setIsTeamDataLoading(false);
        return;
      }

      const { modifiedAssociateTeam } = await processAssociateTeam(
        associateTeamResponse?.data,
        workOrderTeamResponse?.data
      );
      const filteredByRoleData = modifiedAssociateTeam;
      const filteredByAvailableAssociate = filterAssociates(filteredByRoleData);
      const finalData = await processFinalData(
        filteredByAvailableAssociate,
        workOrderTeamResponse?.data,
        onBehalfOfAssociate,
        workOrderId,
        workDayId as string
      );

      setAssociateTeamData(finalData.modifiedData);
      setIsTeamDataLoading(false);
    } catch (e) {
      console.error('Error', e);
      setAssociateTeamData([]);
      setIsTeamDataLoading(false);
    }
  };

  const filterAssociates = (associateData: ITeamMemberData[]) => {
    return associateData.filter((member: IUser) => {
      const shortlisted = checkedSupervisors.some((supervisor: any) => supervisor.id === member.userId);
      return !shortlisted;
    });
  };

  const getSupervisorDetails = (data: WorkOrderTeam[]) => {
    return data.filter((member) => member.isSupervisor);
  };

  const getAssociateDetails = (data: any[]) => {
    return data.filter((member) => !member.isSupervisor);
  };

  const processAssociateTeam = async (associateTeamData: any[], workOrderTeamData: any[]) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedAssociateTeam = await Promise.all(
      associateTeamData.map(async (user) => {
        const { roleName = '', roleId = '' } = user?.roles?.[0] || {};
        const associateDetails = await CustomerApiService.getAssociateDetailsByUserId(user?.cognitoUserId);
        const associateId = associateDetails?.data?.associateId ?? '';
        const memberProfileUrl = await fetchDataFromS3Bucket(
          navigate,
          `associate/${associateId}/profile-pictures/${associateId}`
        );
        const memberData = workOrderTeamData.find((associate) => associate.userId === user.userId);

        return {
          id: user?.userId,
          userId: user?.userId,
          name: user?.username,
          isChecked: false,
          profileImg: memberProfileUrl,
          role: roleName,
          roleId,
          isSupervisor: false,
          isDisabled: false,
          isJobNotAccepted: false,
          associateStartDate: memberData?.associateStartDate ?? '',
          associateEndDate: memberData?.associateEndDate ?? '',
        };
      })
    );
    return { modifiedAssociateTeam, tempCheckedMember };
  };

  const processFinalData = async (
    data: any[],
    workOrderTeamData: any[],
    onBehalfOfAssociate: boolean,
    workOrderId: string,
    workDayId: string
  ) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedData = await Promise.all(
      data.map(async (member) => {
        const tempMember = { ...member };
        let punchType;
        const workOrderTeamMember = workOrderTeamData.find((teamMember) => teamMember?.userId === member?.userId);
        if (workOrderScheduleId) {
          const latestPunchInOutByWorkDayResponse = await WorkOrdersApiService.getLatestPunchInOutByWorkDay(
            workOrderScheduleId,
            member?.userId
          );
          punchType = latestPunchInOutByWorkDayResponse.data?.punchType;
        }

        const associateInfo = workOrderTeamData.find(
          (associate) => associate.userId === member.userId && !associate.isSupervisor
        );
        const isJobNotAccepted = !associateInfo?.jobAccepted;
        const isCannotMakeJob = associateInfo?.cantMakeIt;

        if (workOrderTeamMember) {
          const checkedMember = {
            ...tempMember,
            isChecked: true,
            isDisabled: onBehalfOfAssociate,
            teamId: workOrderTeamMember.teamId || '',
            isSupervisor: workOrderTeamMember.isSupervisor,
            workDayId: workDayId,
            punchType: punchType,
            isJobNotAccepted: isJobNotAccepted,
            isCannotMakeJob: isCannotMakeJob,
          };
          tempCheckedMember.push(checkedMember);
          return checkedMember;
        }
        return tempMember;
      })
    );
    return { modifiedData, checkedAssociates: tempCheckedMember };
  };

  const getPunchInDetails = async (workOrderId: string) => {
    const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
      workOrderId,
      woScheduleMasterId: woScheduleMasterId,
    });
    const associateDetails = workOrderTeamResponse.data.find((member: any) => !member.isSupervisor);
    const workDayId = associateDetails?.workDayTeam?.workDayId;
    if (workDayId) {
      const latestPunchInOutResponse = await WorkOrdersApiService.getLatestPunchRecords(workDayId);
      if (!isEmpty(latestPunchInOutResponse?.data) && isEmpty(latestPunchInOutResponse?.data?.errorCode)) {
        setIsAssociatePunchedIn(!!latestPunchInOutResponse?.data?.punchType);
      }
    }
  };

  const getWorkOrderDetails = async () => {
    setIsDataLoading(true);
    try {
      if (jobData?.jobId && specificDate) {
        const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: jobData?.jobId });

        const workOrderResponseByJobId = workOrderResponse?.data?.filter(
          (data: { jobId: string }) => data?.jobId === jobData?.jobId
        );

        if (workOrderResponse?.data && workOrderResponse?.errors?.length === 0) {
          const workOrderData = workOrderResponse?.data[0];
          setWorkOrderId(workOrderResponseByJobId?.[0]?.workOrderId);

          const endDate = new Date(workOrderResponseByJobId?.[0]?.jobEndDate);
          const formattedEndDate = formatDate(endDate.toISOString());
          setJobEndDate(formattedEndDate);

          await getPunchInDetails(workOrderResponseByJobId?.[0]?.workOrderId);
          setWorkOrderData(workOrderData);

          const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
            workOrderId: workOrderResponseByJobId?.[0]?.workOrderId,
            woScheduleMasterId: woScheduleMasterId,
          });

          const workDayId = workOrderTeamResponse?.data[0]?.workDayId ?? '';
          setWorkDayId(workDayId);
          await fetchVendorTeam(workOrderTeamResponse);
          await fetchAssociateTeam(workOrderData?.workOrderId, workOrderTeamResponse);
        }
      }
      setIsDataLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    const currentTime = moment().toJSON();
    const isCurrentShiftIsInFuture = moment(moment(specificDate).local().format('YYYY-MM-DD'), 'YYYY-MM-DD').isAfter(
      moment(currentTime, 'YYYY-MM-DD')
    );
    if (isCurrentShiftIsInFuture) {
      setIsFutureShiftSelected(true);
    } else {
      setIsFutureShiftSelected(false);
    }
  }, [specificDate]);

  useEffect(() => {
    getWorkOrderDetails();
  }, [specificDate, jobId]);

  return (
    <>
      {jobsLoader ? (
        <LoaderTag>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </LoaderTag>
      ) : (
        <>
          <AssociateOnTheJobCard>
            <AssignmentTeamCard>
              {workOrderId && (
                <SupervisorPunchIn
                  teamData={teamData}
                  associateTeamData={associateTeamData}
                  isTeamDataLoading={isTeamDataLoading || isDataLoading}
                  workOrderId={workOrderId}
                  workOrderData={workOrderData}
                  address={FacilityData?.address}
                  isAssociatePunchedIn={isAssociatePunchedIn}
                  workDayId={workDayId}
                  isFutureShiftSelected={isFutureShiftSelected}
                  specificDate={specificDate}
                  jobEndDate={jobEndDate}
                  getWorkOrderDetails={getWorkOrderDetails}
                  woScheduleMasterId={woScheduleMasterId}
                  workOrderScheduleId={workOrderScheduleId}
                  scheduleData={scheduleData}
                  shiftData={shiftData}
                />
              )}
            </AssignmentTeamCard>
            <SnackbarMessage
              open={snackbarOpen}
              successMessage={snackbarMessage}
              errorMessage={snackbarMessage}
              severity={snackbarSeverity}
              onClose={() => setSnackbarOpen(false)}
            />
          </AssociateOnTheJobCard>
        </>
      )}
    </>
  );
};

export default AssociateOnTheJob;
