/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertColor, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  getAcronymName,
  formatDate,
  hours,
  getTimeFormat,
  getTimeFromGivenDate,
  parseTime,
  ErrorCodesForPunChIn,
  getStoredCustomerDetails,
  getAuthData,
  fetchDataFromS3Bucket,
  RolesEnum,
  isDateBefore,
  formattingDate,
  convertToReadableFormat,
} from '../../../../Shared/Utilities/utils';
import { ITeamMemberData, IWorkOrderTeam } from '../JobDetails/JobAssignmentCard';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { BooleanType, CreateWorkOrderTeam, PunchType, UserForUserManagement } from '../../../../API';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import {
  buttonStyles,
  DateRange,
  HeadingText,
  LogoWrapper,
  NewDatePickerContainer,
  PunchCardContainerWrapper,
  PunchInButton,
  PunchInHeader,
  PunchinHeading,
  PunchTeamContainer,
  TagWrapper,
  TagWrapperText,
  TeamCard,
  TeamCardOuterDiv,
  TeamDisplayDiv,
} from '../JobDetails/OneTimeJobDetails.Style';
import theme from '../../../../Shared/Themes/theme';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { Controller, useForm } from 'react-hook-form';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { DateFieldWrapper, ErrorMsg } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import Modal from '../../Utilities/Shared/Components/Modal';
import moment, { Moment } from 'moment';
import AssociateApiService from '../../../../Shared/Services/AssociateService';
import _, { isEmpty } from 'lodash';
import { IUser, Role, UserType } from '../../../../Shared/Constants/App';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import { useNavigate } from 'react-router-dom';
import { LoaderTag } from '../ActionItems/ActionItems.Style';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import AddTeamMember from './AddTeamMember';
import { AllShiftDetailsQuery, SchedulesByDateQuery } from './AssociateCard';

interface SupervisorPunchIn {
  teamData: ITeamMemberData[];
  associateTeamData: ITeamMemberData[];
  isTeamDataLoading: boolean;
  workOrderId: string;
  workOrderData: any;
  address: any;
  isAssociatePunchedIn: any;
  workDayId: string;
  isFutureShiftSelected: boolean;
  specificDate: any;
  jobEndDate: any;
  getWorkOrderDetails: () => Promise<void>;
  woScheduleMasterId: string;
  workOrderScheduleId: string;
  scheduleData: SchedulesByDateQuery;
  shiftData: AllShiftDetailsQuery[];
}

export interface AssociateShiftDateProps {
  associateStartDate: string;
  associateEndDate: string;
  userId: string;
}

const SupervisorPunchIn = ({
  teamData,
  associateTeamData,
  isTeamDataLoading,
  shiftData,
  workOrderId,
  workOrderData,
  isFutureShiftSelected,
  specificDate,
  getWorkOrderDetails,
  workOrderScheduleId,
  woScheduleMasterId,
  scheduleData,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: SupervisorPunchIn) => {
  const [punchInTeamData, setPunchInTeamData] = useState<ITeamMemberData[]>([]);
  const [punchOutTeamData, setPunchOutTeamData] = useState<ITeamMemberData[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [hoursList, setHoursList] = useState<any[]>([]);
  const [isPunchedInModalOpen, setIsPunchedInModalOpen] = useState<boolean>(false);
  const [isPunchedOutModalOpen, setIsPunchedOutModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddSupervisorsModalOpen, setIsAddSupervisorsModalOpen] = useState<boolean>(false);
  const [isAddAssociatesModalOpen, setIsAddAssociatesModalOpen] = useState<boolean>(false);
  const [selectedSupervisors, setSelectedSupervisors] = useState<any>([]);
  const [selectedAssociates, setSelectedAssociates] = useState<any>([]);
  const [associatesTeam, setAssociatesTeam] = useState<ITeamMemberData[]>([]);
  const [originalAssociatesTeam, setOriginalAssociatesTeam] = useState<ITeamMemberData[]>([]);
  const [supervisorsTeam, setSupervisorsTeam] = useState<ITeamMemberData[]>([]);
  const [originalSupervisorsTeam, setOriginalSupervisorsTeam] = useState<ITeamMemberData[]>([]);
  const [isAssociateDataLoading, setIsAssociateDataLoading] = useState<boolean>(false);
  const [isSupervisorDataLoading, setIsSupervisorDataLoading] = useState<boolean>(false);
  const [isAssignButtonLoading, setIsAssignButtonLoading] = useState<boolean>(false);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
  const [isDeleteModelOpen, setDeleteModalOpen] = useState(false);
  const [editAssociateShiftDate, setEditAssociateShiftDate] = useState<AssociateShiftDateProps>({
    userId: '',
    associateStartDate: '',
    associateEndDate: '',
  });
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });

  const { t } = useTranslation(['supervisorPunchIn', 'assignJob', 'oneTimeJob', 'calendar', 'messages']);
  const { textField, PunchInButtonStyles } = buttonStyles;
  const { control, handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: {
      punchInDate: specificDate ? formatDate(specificDate) : null,
      punchOutDate: null,
      punchInTime: '',
      punchOutTime: '',
    },
  });

  const watchFields = watch();
  const { isDistrictManager, role } = getAuthData();
  const navigate = useNavigate();

  const disablePunchInButton = () => {
    const { punchInDate, punchInTime } = getValues();
    return !(punchInDate && punchInTime);
  };

  const disablePunchOutButton = () => {
    const { punchOutDate, punchOutTime } = getValues();
    return !(punchOutDate && punchOutTime);
  };

  const handlePunchIn = (id: any) => {
    setIsPunchedInModalOpen(true);
    const updatedTeamData = associateTeamData.filter((member) => member?.id === id);
    setPunchInTeamData(updatedTeamData);
    return true;
  };

  const handlePunchOut = (id: any) => {
    setIsPunchedOutModalOpen(true);
    const updatedTeamData = associateTeamData.filter((member) => member?.id === id);
    setPunchOutTeamData(updatedTeamData);
    return true;
  };

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const renderDuration = (member: ITeamMemberData) => {
    if (!member?.isSupervisor && member?.associateStartDate && member?.associateEndDate) {
      const associateStartDate = new Date(member.associateStartDate);
      const associateEndDate = new Date(member.associateEndDate);
      const formattedStartDate = formatDate(associateStartDate.toISOString());
      const formattedEndDate = formatDate(associateEndDate.toISOString());
      return (
        <PunchInButton>
          {t('dashboard:duration')}
          <DateRange>
            {formattedStartDate} - {formattedEndDate}
          </DateRange>
        </PunchInButton>
      );
    }
  };

  const renderTag = (member: ITeamMemberData) => {
    if (!member?.isSupervisor && member.isJobNotAccepted) {
      return (
        <TagWrapper>
          <TagWrapperText>{t('assignJob:jobNotYetAccepted')}</TagWrapperText>
        </TagWrapper>
      );
    }
    if (!member?.isSupervisor && member?.isCannotMakeJob) {
      return (
        <TagWrapper>
          <TagWrapperText>{t('assignJob:canNotMakeIt')}</TagWrapperText>
        </TagWrapper>
      );
    }
  };

  const renderTeamContent = (teamData: ITeamMemberData[]) => {
    return (
      <>
        {teamData?.length > 0 && (
          <TeamDisplayDiv>
            {teamData?.map((member: ITeamMemberData) => {
              return (
                <>
                  <TeamCardOuterDiv>
                    {renderTag(member)}
                    <TeamCard style={{ padding: '20px' }} key={member?.id}>
                      <div style={{ display: 'flex' }}>
                        <LogoWrapper>
                          <div>{getAcronymName(member?.name as string).toUpperCase()}</div>
                        </LogoWrapper>
                        <PunchTeamContainer>
                          <div className="Title">{member?.name}</div>
                          {member?.isSupervisor && (
                            <div className="Position">{convertToReadableFormat(member.role)}</div>
                          )}
                          {!member?.isSupervisor &&
                            (isEmpty(member?.punchType) || member?.punchType === PunchType.NoShow ? (
                              <Button
                                sx={{
                                  ...PunchInButtonStyles,
                                }}
                                onClick={() => handlePunchIn(member?.id)}
                                disabled={
                                  member?.isJobNotAccepted ||
                                  isFutureShiftSelected ||
                                  member?.isCannotMakeJob ||
                                  scheduleData?.isRescheduled
                                }>
                                <PunchInButton
                                  style={{
                                    opacity:
                                      member?.isJobNotAccepted ||
                                      isFutureShiftSelected ||
                                      member?.isCannotMakeJob ||
                                      scheduleData?.isRescheduled
                                        ? 0.5
                                        : 1,
                                  }}>
                                  {t('supervisorPunchIn:punchIn')}
                                </PunchInButton>
                              </Button>
                            ) : (
                              <Button
                                sx={{
                                  ...PunchInButtonStyles,
                                }}
                                onClick={() => handlePunchOut(member.id)}
                                disabled={
                                  member?.isJobNotAccepted ||
                                  member?.punchType === PunchType.PunchOut ||
                                  isFutureShiftSelected ||
                                  member?.isCannotMakeJob ||
                                  scheduleData?.isRescheduled
                                }>
                                <PunchInButton
                                  style={{
                                    opacity:
                                      member?.isJobNotAccepted ||
                                      member?.punchType === PunchType.PunchOut ||
                                      isFutureShiftSelected ||
                                      member?.isCannotMakeJob ||
                                      scheduleData?.isRescheduled
                                        ? 0.5
                                        : 1,
                                  }}>
                                  {member?.punchType === PunchType.PunchOut
                                    ? t('supervisorPunchIn:punchedOut')
                                    : t('supervisorPunchIn:punchOut')}
                                </PunchInButton>
                              </Button>
                            ))}
                          {renderDuration(member)}
                        </PunchTeamContainer>
                      </div>
                    </TeamCard>
                  </TeamCardOuterDiv>
                </>
              );
            })}
          </TeamDisplayDiv>
        )}
      </>
    );
  };

  const handleClosePunchIn = () => {
    setIsPunchedInModalOpen(false);
  };

  const handleClosePunchOut = () => {
    setIsPunchedOutModalOpen(false);
  };

  const handleOnPressPunchIn = async (dateDetails: any) => {
    const { punchInDate, punchInTime } = dateDetails;

    const parsedTime = parseTime(punchInTime);

    const punchTime = moment(punchInDate).format('YYYY-MM-DDTHH:mm:ss.sss');
    const punchIn = moment(punchTime).set('hours', Number(parsedTime.hours)).set('minutes', Number(parsedTime.minutes));
    const punchInTimeInUst = moment(punchIn).toISOString();

    try {
      setIsLoading(true);

      const localDate = new Date();
      const formattedDate = localDate.toLocaleString();
      const formattedTime = moment(localDate).toISOString();

      const punchInTimeInFuture = moment(punchInTimeInUst).isAfter(moment(formattedTime));

      if (punchInTimeInFuture) {
        const localCurrentDate = moment(formattedDate, 'DD/MM/YYYY, HH:mm:ss').format('MMM D, YYYY');

        const localCurrentTime = getTimeFromGivenDate(formattedTime, false);

        showSnackbar(
          'error',
          `${t('supervisorPunchIn:punchInTimeShouldNotBeInFuture')}  (${localCurrentDate} , ${localCurrentTime.hours}:${
            localCurrentTime.formattedMins
          } ${localCurrentTime.meridiem})`
        );
      } else {
        await performPunchIn(punchIn);
      }
      setIsLoading(false);
    } catch (error) {
      showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchIn')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const performPunchIn = async (punchIn: Moment) => {
    const punchInInput = [
      {
        userId: punchInTeamData?.[0]?.id,
        punchTime: moment(punchIn).toISOString(),
        punchType: PunchType.PunchIn,
        locationLatitude: currentLocation.lat,
        locationLongitude: currentLocation?.lng,
      },
    ];

    if (workOrderScheduleId) {
      const punchInResponse = await AssociateApiService.addPunchInOutBySupervisor(workOrderScheduleId, punchInInput);

      if (punchInResponse?.data) {
        if (punchInResponse?.data[0]?.status === ErrorCodesForPunChIn.ALREADY_EXIST) {
          setIsPunchedInModalOpen(false);
          await getWorkOrderDetails();
        } else {
          if (!isEmpty(punchInResponse.errors)) {
            showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchIn')}`);
          } else if (punchInResponse?.data?.length) {
            showSnackbar('success', `${t('supervisorPunchIn:PunchedInSuccessfully')}`);
            setIsPunchedInModalOpen(false);
            await getWorkOrderDetails();
          }
        }
      }
    } else {
      showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchIn')}`);
    }
  };

  const handleOnPressPunchMeOut = async (dateDetails: any) => {
    const { punchOutDate, punchOutTime } = dateDetails;

    const punchTime = moment(punchOutDate).format('YYYY-MM-DDTHH:mm:ss.sss');
    const parsedTime = parseTime(punchOutTime);

    const punchOutTiming = moment(punchTime)
      .set('hours', Number(parsedTime.hours))
      .set('minutes', Number(parsedTime.minutes));
    const punchOut = moment(punchOutTiming).format('YYYY-MM-DDTHH:mm:ss.sss');

    try {
      setIsLoading(true);
      if (workOrderScheduleId) {
        const latestPunchInOutByWorkDayResponse = await WorkOrdersApiService.getLatestPunchInOutByWorkDay(
          workOrderScheduleId,
          punchOutTeamData?.[0]?.id ?? ''
        );

        const latestPunchTime = latestPunchInOutByWorkDayResponse?.data?.punchTime;

        let punchInTime;
        if (latestPunchTime?.endsWith('Z')) {
          punchInTime = latestPunchTime?.slice(0, latestPunchTime?.length - 1);
        } else {
          punchInTime = latestPunchTime ?? '';
        }

        const punchInType = latestPunchInOutByWorkDayResponse.data.punchType;

        const punchOutDateWithTime = formattingDate(moment(punchOut).toISOString());

        const isPunchInTimeIsBeforePunchOut = moment(punchOutDateWithTime).isBefore(moment(punchInTime));
        const isPunchOutNotInFuture = moment(moment(punchOut).toISOString()).isBetween(latestPunchTime, moment());

        if (isPunchInTimeIsBeforePunchOut) {
          const localPunchInDate = formatDate(latestPunchTime, 'MMM D, YYYY');
          const validPunchOutTime = getTimeFromGivenDate(latestPunchTime, true);
          showSnackbar(
            'error',
            `${t('supervisorPunchIn:punchOutTimeShouldBeGreaterThanPunchTime')} ${localPunchInDate} , ${validPunchOutTime.hours}:${
              validPunchOutTime.formattedMins
            } ${validPunchOutTime.meridiem}`
          );
          return;
        } else if (!isPunchOutNotInFuture) {
          const localPunchInDate = formatDate(latestPunchTime, 'MMM D, YYYY');
          const localPunchInTime = getTimeFromGivenDate(latestPunchTime, true);
          const localDate = new Date();
          const formattedDate = localDate.toLocaleString();
          const formattedTime = moment(localDate).toISOString();

          const localCurrentDate = moment(formattedDate, 'DD/MM/YYYY, HH:mm:ss').format('MMM D, YYYY');

          const localCurrentTime = getTimeFromGivenDate(formattedTime, false);

          showSnackbar(
            'error',
            `${t('supervisorPunchIn:punchOutTimeShouldBeInBetweenPunchTime')} (${localPunchInDate} , ${localPunchInTime.hours}:${
              localPunchInTime.formattedMins
            } ${localPunchInTime.meridiem}) ${t('supervisorPunchIn:currentTime')} (${localCurrentDate} , ${localCurrentTime.hours}:${
              localCurrentTime.formattedMins
            } ${localCurrentTime.meridiem})`
          );
        } else if (punchInType === PunchType.PunchOut) {
          showSnackbar('error', `${t('supervisorPunchIn:alreadyPunchedOutTitle')}`);
          setIsPunchedOutModalOpen(false);
          await getWorkOrderDetails();
          return;
        } else {
          await performPunchOut(punchOut);
        }
      } else {
        showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
      }
      setIsLoading(false);
    } catch (error) {
      showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const performPunchOut = async (punchOut: string) => {
    const punchOutInput = [
      {
        userId: punchOutTeamData?.[0]?.id,
        punchTime: moment(punchOut).toISOString(),
        punchType: PunchType.PunchOut,
        locationLatitude: currentLocation.lat,
        locationLongitude: currentLocation?.lng,
      },
    ];

    if (workOrderScheduleId) {
      const punchInOutResponse = await AssociateApiService.addPunchInOutBySupervisor(
        workOrderScheduleId,
        punchOutInput
      );
      if (punchInOutResponse?.data) {
        setIsPunchedOutModalOpen(false);
        showSnackbar('success', `${t('supervisorPunchIn:PunchedOutSuccessfully')}`);
        await getWorkOrderDetails();
      } else {
        showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
      }
    } else {
      showSnackbar('error', `${t('supervisorPunchIn:ErrorOccurredWhilePunchOut')}`);
    }
  };

  const renderPunchInHeaderContent = () => {
    return <HeadingText>{t('supervisorPunchIn:punchIn')}</HeadingText>;
  };

  const renderPunchOutHeaderContent = () => {
    return <HeadingText>{t('supervisorPunchIn:punchOut')}</HeadingText>;
  };

  const renderPunchInBodyContent = () => {
    return (
      <>
        <NewDatePickerContainer>
          <DateSelection
            requiredText={t('supervisorPunchIn:punchInDateFieldReq')}
            control={control}
            isExcludePastDate={true}
            fieldName={FieldNames.punchInDate ?? null}
            label={t('supervisorPunchIn:Date')}
            iconColor="black"
            showSpecificRangeDates={{
              from: specificDate,
              to: new Date(),
            }}
          />
          <Controller
            control={control}
            name={FieldNames.punchInTime}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={t('supervisorPunchIn:Time')}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{
                              padding: 0,
                            }}>
                            <img src={Icons.DropdownUpIcon} alt={'up'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>

                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              );
            }}
          />
        </NewDatePickerContainer>
      </>
    );
  };

  const renderPunchOutBodyContent = () => {
    return (
      <>
        <NewDatePickerContainer>
          <DateSelection
            requiredText={t('supervisorPunchIn:punchOutDateFieldReq')}
            control={control}
            isExcludePastDate={true}
            fieldName={FieldNames.punchOutDate ?? null}
            label={t('supervisorPunchIn:Date')}
            iconColor="black"
            showSpecificRangeDates={{
              from: specificDate,
              to: undefined,
            }}
          />
          <Controller
            control={control}
            name={FieldNames.punchOutTime}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={t('supervisorPunchIn:Time')}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{
                              padding: 0,
                            }}>
                            <img src={Icons.DropdownUpIcon} alt={'up'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>

                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              );
            }}
          />
        </NewDatePickerContainer>
      </>
    );
  };

  const renderAddSupervisorsHeaderContent = () => {
    return <HeadingText>{t('assignJob:assignSupervisors')}</HeadingText>;
  };

  const renderAddAssociatesHeaderContent = () => {
    return <HeadingText>{t('assignJob:assignAssociates')}</HeadingText>;
  };

  const handleAddSupervisors = async () => {
    try {
      setIsAssignButtonLoading(true);
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;
      const finalMembers = selectedSupervisors.filter((data: { isAlreadyChecked: any }) => !data?.isAlreadyChecked);
      const createScheduleAssignmentsInput: CreateWorkOrderTeam[] = finalMembers.map((item: any) => {
        if (item.teamId) {
          return {
            userId: item?.id || '',
            vendorId,
            roleId: item?.roleId as string,
            isSupervisor: item?.isSupervisor,
            teamId: item?.teamId,
            associateStartDate: item?.associateStartDate,
            associateEndDate: item?.associateStartDate,
          };
        }
        return {
          userId: item?.id || '',
          vendorId,
          roleId: item?.roleId as string,
          isSupervisor: item?.isSupervisor,
          associateStartDate: item?.associateStartDate,
          associateEndDate: item?.associateStartDate,
        };
      });
      const confirmedTeamResponse = await WorkOrdersApiService.addTeamAssignmentWorkOrderTeam({
        workOrderId,
        createScheduleAssignmentsInput,
        woScheduleMasterId,
      });
      if (confirmedTeamResponse.errors?.length) {
        showSnackbar('error', confirmedTeamResponse.errors[0].message);
        setIsAssignButtonLoading(false);
      } else {
        showSnackbar('success', `${t('messages:successfullyAssignedSupervisor')}`);
        setIsAssignButtonLoading(false);
        handleCloseAddButton(Role.Supervisor);
        await getWorkOrderDetails();
        await fetchAllVendorTeam(workOrderId);
        await fetchAllAssociateTeam(workOrderId);
      }
    } catch (e) {
      setIsAssignButtonLoading(false);
      console.log('Error:', e);
    }
  };

  const parseDateString = (dateString: string | null): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const toISOStringOrNull = (date: Date | null | undefined): string | null => {
    return date ? new Date(date).toISOString() : null;
  };

  const handleAddAssociates = async (startDate: string, endDate: string) => {
    setIsConfirmButtonLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;

      if (isDateBefore(endDate, startDate)) {
        setIsConfirmButtonLoading(false);
        showSnackbar('error', `${t('oneTimeJob:endDateShouldBeGreaterThanStartDate')}`);
        return;
      }

      const finalAssociates = selectedAssociates.filter((data: { isAlreadyChecked: any }) => !data?.isAlreadyChecked);
      const editTeamMembers = selectedAssociates.filter(
        (data: { id: string }) => data?.id === editAssociateShiftDate?.userId
      );
      let finalMembers;
      if (isEmpty(editAssociateShiftDate?.userId)) {
        finalMembers = finalAssociates;
      } else {
        finalMembers = editTeamMembers;
      }
      if (finalMembers.length) {
        const createScheduleAssignmentsInput = getTeamAssignments(finalMembers, startDate, endDate, vendorId);

        const confirmedTeamResponse = await saveTeamAssignments(
          createScheduleAssignmentsInput,
          editAssociateShiftDate?.userId
        );

        if (confirmedTeamResponse.errors?.length) {
          showSnackbar('error', confirmedTeamResponse.errors[0].message);
        } else {
          showSnackbar('success', `${t('messages:successfullyAssignedAssociate')}`);
          handleCloseAddButton(Role.Associate);
          await getWorkOrderDetails();
          await fetchAllVendorTeam(workOrderId);
          await fetchAllAssociateTeam(workOrderId);
        }
      }
      setIsConfirmButtonLoading(false);
    } catch (e) {
      setIsConfirmButtonLoading(false);
      console.error('Error', e);
    }
  };

  const getTeamAssignments = (
    finalMembers: any[],
    startDate: string,
    endDate: string,
    vendorId: string
  ): CreateWorkOrderTeam[] => {
    const parsedStartDate = parseDateString(startDate);
    const parsedEndDate = parseDateString(endDate);

    return finalMembers.map((item: any) => {
      const newMemberInput: CreateWorkOrderTeam = {
        userId: item.id || '',
        vendorId,
        roleId: item.roleId as string,
        isSupervisor: item.isSupervisor,
      };

      if (item.teamId) {
        return {
          ...newMemberInput,
          teamId: item.teamId,
          associateStartDate: toISOStringOrNull(parsedStartDate),
          associateEndDate: toISOStringOrNull(parsedEndDate),
        };
      } else if (!item.isSupervisor) {
        return {
          ...newMemberInput,
          associateStartDate: toISOStringOrNull(parsedStartDate),
          associateEndDate: toISOStringOrNull(parsedEndDate),
        };
      }
      return newMemberInput;
    });
  };

  const saveTeamAssignments = async (createScheduleAssignmentsInput: CreateWorkOrderTeam[], userId?: string) => {
    if (isEmpty(userId)) {
      return await WorkOrdersApiService.addTeamAssignmentWorkOrderTeam({
        workOrderId,
        createScheduleAssignmentsInput,
        woScheduleMasterId: woScheduleMasterId,
      });
    } else {
      return await WorkOrdersApiService.updateTeamAssignmentWorkOrderTeam({
        workOrderId,
        updateScheduleAssignmentsInput: createScheduleAssignmentsInput,
      });
    }
  };

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteTeamMember = async (teamMemberDetails: ITeamMemberData | undefined) => {
    try {
      setIsDeleteButtonLoading(true);
      const userId = teamMemberDetails?.id ?? '';
      const deleteAssociateResponse = await WorkOrdersApiService.deleteTeamAssignmentWorkOrderTeam({
        workOrderId,
        userIds: [userId],
        woScheduleMasterId: woScheduleMasterId,
      });
      console.log('Team member deleted successfully', deleteAssociateResponse);
      if (teamMemberDetails?.role === Role.Associate) {
        showSnackbar('success', `${t('messages:successfullyDeletedAssociate')}`);
      } else {
        showSnackbar('success', `${t('messages:successfullyDeletedSupervisor')}`);
      }
      setIsDeleteButtonLoading(false);
      handleCloseDeleteModal();
      handleCloseAddButton(teamMemberDetails?.role === Role.Associate ? Role.Associate : Role.Supervisor);
      await getWorkOrderDetails();
      await fetchAllVendorTeam(workOrderId);
      await fetchAllAssociateTeam(workOrderId);
    } catch (error) {
      setIsDeleteButtonLoading(false);
      console.error('Failed to delete team member', error);
    } finally {
      setIsDeleteButtonLoading(false);
    }
  };

  const renderAddSupervisorsBodyContent = (type: Role) => {
    return (
      <>
        <AddTeamMember
          teamMembers={supervisorsTeam}
          setTeamMembers={setSupervisorsTeam}
          originalTeam={originalSupervisorsTeam}
          setOriginalTeam={setOriginalSupervisorsTeam}
          selectedTeamMembers={selectedSupervisors}
          setSelectedTeamMembers={setSelectedSupervisors}
          isDataLoading={isSupervisorDataLoading}
          onClose={() => handleCloseAddButton(type)}
          handleAddTeamMember={handleAddSupervisors}
          assignmentType={type}
          workOrderData={workOrderData}
          isButtonLoading={isAssignButtonLoading}
          deleteTeamMember={true}
          editTeamMember={false}
          handleDeleteTeamMember={handleDeleteTeamMember}
          isDeleteModelOpen={isDeleteModelOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          isDeleteButtonLoading={isDeleteButtonLoading}
          handleCloseDeleteModal={handleCloseDeleteModal}
          setEditAssociateShiftDate={setEditAssociateShiftDate}
          shiftData={shiftData}
        />
      </>
    );
  };

  const renderAddAssociatesBodyContent = (type: Role) => {
    return (
      <>
        <AddTeamMember
          teamMembers={associatesTeam}
          setTeamMembers={setAssociatesTeam}
          originalTeam={originalAssociatesTeam}
          setOriginalTeam={setOriginalAssociatesTeam}
          selectedTeamMembers={selectedAssociates}
          setSelectedTeamMembers={setSelectedAssociates}
          isDataLoading={isAssociateDataLoading}
          onClose={() => handleCloseAddButton(type)}
          handleAddTeamMember={handleAddAssociates}
          assignmentType={type}
          workOrderData={workOrderData}
          isButtonLoading={isConfirmButtonLoading}
          deleteTeamMember={true}
          editTeamMember={true}
          handleDeleteTeamMember={handleDeleteTeamMember}
          isDeleteModelOpen={isDeleteModelOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          isDeleteButtonLoading={isDeleteButtonLoading}
          handleCloseDeleteModal={handleCloseDeleteModal}
          editAssociateShiftDate={editAssociateShiftDate}
          setEditAssociateShiftDate={setEditAssociateShiftDate}
          shiftData={shiftData}
        />
      </>
    );
  };

  const handleAddButton = (roleType: Role) => {
    if (roleType === Role.Supervisor) {
      setIsAddSupervisorsModalOpen(true);
    } else if (roleType === Role.Associate) {
      setIsAddAssociatesModalOpen(true);
    }
  };

  const handleCloseAddButton = (roleType: Role) => {
    if (roleType === Role.Supervisor) {
      setIsAddSupervisorsModalOpen(false);
    } else if (roleType === Role.Associate) {
      setIsAddAssociatesModalOpen(false);
    }
  };

  const fetchAllAssociateTeam = async (
    workOrderId: string,
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    const onBehalfOfAssociate = false;
    if (userId || vendorId) {
      setIsAssociateDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

        let associateTeamResponse;
        associateTeamResponse = await getAssociateTeamResponse(userDetails, vendorId, searchString, pageNumber, limit);

        if (associateTeamResponse?.metadata?.totalCount > 10) {
          associateTeamResponse = await getAssociateTeamResponse(
            userDetails,
            vendorId,
            searchString,
            pageNumber,
            associateTeamResponse?.metadata?.totalCount
          );
        }

        if (associateTeamResponse.errors.length) {
          showSnackbar('error', associateTeamResponse?.errors[0]?.message);
          setIsAssociateDataLoading(false);
        }
        const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
          workOrderId,
          woScheduleMasterId: woScheduleMasterId,
        });
        if (workOrderTeamResponse.errors.length) {
          showSnackbar('error', workOrderTeamResponse?.errors[0]?.message);
          setIsAssociateDataLoading(false);
          return;
        }
        const modifiedData = await processAssociateTeamData(
          associateTeamResponse.data,
          workOrderTeamResponse.data,
          onBehalfOfAssociate
        );

        setAssociatesTeam(modifiedData);
        setOriginalAssociatesTeam(modifiedData);
        setIsAssociateDataLoading(false);
      } catch (e) {
        console.error('Error', e);
        setAssociatesTeam([]);
        setOriginalAssociatesTeam([]);
        setIsAssociateDataLoading(false);
      }
    }
  };

  const processAssociateTeamData = async (
    vendorTeamData: any[],
    workOrderTeamData: any[],
    onBehalfOfAssociate: boolean
  ) => {
    const tempCheckedMember: ITeamMemberData[] = [];

    const modifiedVendorTeam = await Promise.all(vendorTeamData?.map((user) => processUserData(user)));

    const filteredByRoleData = filterByRole(modifiedVendorTeam, onBehalfOfAssociate);
    const filteredByAvailableAssociate = filterAssociates(filteredByRoleData);

    const modifiedData = await Promise.all(
      filteredByAvailableAssociate.map((member) =>
        processMemberData(member, workOrderTeamData, tempCheckedMember, onBehalfOfAssociate)
      )
    );

    if (!onBehalfOfAssociate) {
      setSelectedAssociates([...tempCheckedMember]);
    }

    return modifiedData;
  };

  const processUserData = async (user: UserForUserManagement) => {
    const { roleName = '', roleId = '' } = !isEmpty(user?.roles) ? user?.roles?.[0] || {} : {};
    const associateDetails = await CustomerApiService.getAssociateDetailsByUserId(user?.cognitoUserId as string);
    const associateId = associateDetails?.data?.associateId ?? '';
    const memberProfileUrl = await fetchDataFromS3Bucket(
      navigate,
      `associate/${associateId}/profile-pictures/${associateId}`
    );

    return {
      id: user?.userId,
      userId: user?.userId,
      name: user?.username,
      isChecked: false,
      profileImg: memberProfileUrl,
      role: roleName,
      roleId,
      isSupervisor: false,
      isDisabled: false,
      isJobNotAccepted: false,
    };
  };

  const processMemberData = (
    member: any,
    workOrderTeamData: any[],
    tempCheckedMember: ITeamMemberData[],
    onBehalfOfAssociate: boolean
  ) => {
    const tempMember = { ...member };
    const workOrderTeamMember = workOrderTeamData?.find(
      (teamMember: IWorkOrderTeam) => teamMember?.userId === member?.userId
    );

    if (!workOrderTeamMember) return tempMember;

    const isChecked = true;
    const isAlreadyChecked = true;
    const teamId = workOrderTeamMember.teamId || '';
    const isSupervisor = workOrderTeamMember.isSupervisor;

    if (onBehalfOfAssociate || !isSupervisor) {
      tempCheckedMember.push({
        ...tempMember,
        isChecked,
        isAlreadyChecked,
        isDisabled: onBehalfOfAssociate,
        teamId,
        isSupervisor,
        associateStartDate: workOrderTeamMember?.associateStartDate,
        associateEndDate: workOrderTeamMember?.associateEndDate,
      });

      return {
        ...tempMember,
        isChecked,
        isAlreadyChecked,
        isDisabled: onBehalfOfAssociate,
        teamId,
        isSupervisor,
        associateStartDate: workOrderTeamMember?.associateStartDate,
        associateEndDate: workOrderTeamMember?.associateEndDate,
      };
    }

    return { ...tempMember, teamId };
  };

  const filterByRole = (team: any[], onBehalfOfAssociate: boolean) => {
    if (onBehalfOfAssociate) return team;
    return team.filter((item) => item?.role === RolesEnum.AssociateTechnician);
  };

  const getAssociateTeamResponse = async (
    userDetails: any,
    vendorId: string,
    searchString?: string,
    pageNumber?: number,
    limit?: number
  ) => {
    return isDistrictManager
      ? await WorkOrdersApiService.getVendorTeam(
          userDetails?.data?.userId,
          '',
          BooleanType.True,
          searchString || '',
          pageNumber,
          limit,
          true
        )
      : await OrganizationService.getAllUsers(vendorId, '', '', pageNumber, limit, BooleanType.True, true);
  };

  const getVendorTeamResponse = async (
    userDetails: any,
    vendorId: string,
    searchString?: string,
    pageNumber?: number,
    limit?: number
  ) => {
    return isDistrictManager
      ? await WorkOrdersApiService.getVendorTeam(
          userDetails?.data?.userId,
          '',
          BooleanType.False,
          searchString || '',
          pageNumber,
          limit,
          true
        )
      : await CustomerApiService.getAllUsers(vendorId, '', pageNumber, limit, BooleanType.False, true);
  };

  const fetchAllVendorTeam = async (
    workOrderId: string,
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    if (userId || vendorId) {
      setIsSupervisorDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

        let vendorTeamResponse;

        vendorTeamResponse = await getVendorTeamResponse(userDetails, vendorId, searchString, pageNumber, limit);

        if (vendorTeamResponse?.metadata?.totalCount > 10) {
          vendorTeamResponse = await getVendorTeamResponse(
            userDetails,
            vendorId,
            searchString,
            pageNumber,
            vendorTeamResponse?.metadata?.totalCount
          );
        }

        if (vendorTeamResponse.errors.length) {
          showSnackbar('error', vendorTeamResponse?.errors[0]?.message);
          setIsSupervisorDataLoading(false);
          return;
        }
        const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
          workOrderId,
          woScheduleMasterId: woScheduleMasterId,
        });
        if (workOrderTeamResponse.errors.length) {
          showSnackbar('error', workOrderTeamResponse?.errors[0]?.message);
          return;
        }

        const vendorTeamsUsersExcludingAssociates = filterVendorTeams(vendorTeamResponse?.data, isDistrictManager);
        if (!isEmpty(vendorTeamsUsersExcludingAssociates)) {
          await processVendorTeams(vendorTeamsUsersExcludingAssociates, workOrderTeamResponse?.data);
          setIsSupervisorDataLoading(false);
        } else {
          setSupervisorsTeam([]);
          setOriginalSupervisorsTeam([]);
          setIsSupervisorDataLoading(false);
        }
      } catch (e) {
        console.error('Error', e);
        setSupervisorsTeam([]);
        setOriginalSupervisorsTeam([]);
        setIsSupervisorDataLoading(false);
      }
    }
  };

  const filterVendorTeams = (vendorTeamResponseData: any[], isDistrictManager: boolean) => {
    return isDistrictManager
      ? vendorTeamResponseData
      : vendorTeamResponseData.filter((user: any) => {
          return user?.roles[0]?.roleName !== RolesEnum.AssociateTechnician;
        });
  };

  const processVendorTeams = async (
    vendorTeamsUsersExcludingAssociates: UserForUserManagement[],
    workOrderTeamData: IWorkOrderTeam[]
  ) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedData = await Promise.all(
      vendorTeamsUsersExcludingAssociates.map(async (member: UserForUserManagement) => {
        const memberProfileUrl = await fetchDataFromS3Bucket(
          navigate,
          `associate/${member?.userId}/profile-pictures/${member?.userId}`
        );
        const tempMember = createTeamMemberData(member, memberProfileUrl);
        const workOrderTeamMember = workOrderTeamData.find(
          (teamMember: IWorkOrderTeam) => teamMember.userId === member.userId
        );

        if (workOrderTeamMember) {
          if (workOrderTeamMember.isSupervisor) {
            tempCheckedMember.push({
              ...tempMember,
              isChecked: true,
              isAlreadyChecked: true,
              teamId: workOrderTeamMember.teamId || '',
            });
            return { ...tempMember, isChecked: true, isAlreadyChecked: true, teamId: workOrderTeamMember.teamId || '' };
          }
          return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
        }
        return tempMember;
      })
    );
    updateTeamData(tempCheckedMember, modifiedData);
  };

  const createTeamMemberData = (member: UserForUserManagement, profileImg: string): ITeamMemberData => {
    const { roleName = '', roleId = '' } =
      member && !isEmpty(member?.roles) && member?.roles?.length ? member?.roles[0] || {} : {};

    return {
      id: member.userId,
      name: member.username,
      isChecked: false,
      isAlreadyChecked: false,
      profileImg,
      role: roleName,
      roleId,
      isSupervisor: true,
      teamId: '',
    };
  };
  const isOnlyPrimeVendor = workOrderData?.hasChild && !workOrderData?.parentWorkOrderId;

  const updateTeamData = (checkedMembers: ITeamMemberData[], modifiedData: ITeamMemberData[]) => {
    setSelectedSupervisors(() => {
      const combinedArray = [...checkedMembers];
      return _.uniqBy(combinedArray, 'id');
    });
    setSupervisorsTeam(modifiedData);
    setOriginalSupervisorsTeam(modifiedData);
  };

  const filterAssociates = (associateData: ITeamMemberData[]) => {
    return associateData.filter((member: IUser) => {
      const shortlisted = selectedSupervisors.some((supervisor: any) => supervisor.id === member.userId);
      return !shortlisted;
    });
  };

  useEffect(() => {
    setValue(FieldNames.punchInDate, specificDate);
  }, [specificDate]);

  useEffect(() => {
    disablePunchInButton();
    disablePunchOutButton();
  }, [watchFields]);

  useEffect(() => {
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
    fetchAllAssociateTeam(workOrderId);
    fetchAllVendorTeam(workOrderId);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting the current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  return (
    <>
      <PunchCardContainerWrapper>
        {isTeamDataLoading ? (
          <LoaderTag>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginTop: '100px' }} />
          </LoaderTag>
        ) : (
          <>
            <PunchInHeader>
              <PunchinHeading>{t('assignJob:assignedSupervisors')}</PunchinHeading>
              <Button
                onClick={() => handleAddButton(Role.Supervisor)}
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  paddingBottom: '1rem',
                  margin: 0,
                  minWidth: 0,
                  opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                }}
                disabled={scheduleData?.isRescheduled}>
                <img
                  src={Icons.AddTagIcon}
                  alt={t('altTexts:add')}
                  style={{
                    width: '1.75rem',
                    height: '1.75rem',
                    marginRight: '2em',
                    opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                  }}
                />
              </Button>
            </PunchInHeader>
            {teamData.length > 0 ? (
              renderTeamContent(teamData)
            ) : (
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                {t('assignJob:noResultsFound')}
              </Typography>
            )}
            {/* not showing adding associate for dm for flow 2 */}
            {((isDistrictManager && !isOnlyPrimeVendor) || (!isDistrictManager && role === UserType.Vendor)) && (
              <>
                <PunchInHeader>
                  <PunchinHeading>{t('assignJob:assignedAssociates')}</PunchinHeading>
                  <Button
                    onClick={() => handleAddButton(Role.Associate)}
                    sx={{
                      backgroundColor: 'transparent',
                      padding: 0,
                      paddingBottom: '1rem',
                      margin: 0,
                      minWidth: 0,
                      opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                    }}
                    disabled={scheduleData?.isRescheduled}>
                    <img
                      src={Icons.AddTagIcon}
                      alt={t('altTexts:add')}
                      style={{
                        width: '1.75rem',
                        height: '1.75rem',
                        marginRight: '2em',
                        opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                      }}
                    />
                  </Button>
                </PunchInHeader>

                {associateTeamData.length > 0 ? (
                  renderTeamContent(associateTeamData)
                ) : (
                  <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                    {t('assignJob:noResultsFound')}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </PunchCardContainerWrapper>

      <Modal
        open={isPunchedInModalOpen}
        onClose={handleClosePunchIn}
        primaryButtonLoading={isLoading}
        primaryButtonLabel={t('supervisorPunchIn:punchIn')}
        secondaryButtonLabel={t('assignJob:cancel')}
        renderHeader={renderPunchInHeaderContent()}
        renderBody={renderPunchInBodyContent()}
        primaryAction={handleSubmit(handleOnPressPunchIn)}
        secondaryAction={handleClosePunchIn}
        styles={{
          '& .MuiPaper-root': {
            width: '32rem',
          },
        }}
        primaryButtonDisabling={disablePunchInButton()}
      />
      <Modal
        open={isPunchedOutModalOpen}
        onClose={handleClosePunchOut}
        primaryButtonLoading={isLoading}
        primaryButtonLabel={t('supervisorPunchIn:punchOut')}
        secondaryButtonLabel={t('assignJob:cancel')}
        renderHeader={renderPunchOutHeaderContent()}
        renderBody={renderPunchOutBodyContent()}
        primaryAction={handleSubmit(handleOnPressPunchMeOut)}
        secondaryAction={handleClosePunchOut}
        styles={{
          '& .MuiPaper-root': {
            width: '32rem',
          },
        }}
        primaryButtonDisabling={disablePunchOutButton()}
      />
      <Modal
        open={isAddSupervisorsModalOpen}
        onClose={() => handleCloseAddButton(Role.Supervisor)}
        renderHeader={renderAddSupervisorsHeaderContent()}
        renderBody={renderAddSupervisorsBodyContent(Role.Supervisor)}
      />

      <Modal
        open={isAddAssociatesModalOpen}
        onClose={() => handleCloseAddButton(Role.Associate)}
        renderHeader={renderAddAssociatesHeaderContent()}
        renderBody={renderAddAssociatesBodyContent(Role.Associate)}
      />

      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default SupervisorPunchIn;
